import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./Components/Language/i18n.js";
import Home from "./Components/Pages/Home/Home.js";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy/PrivacyPolicy.js";
import TermsCondition from "./Components/Pages/TermsCondition/TermsCondition.js";
import UndefinePage from "./Components/Pages/404 Page/UndefinePage.js";
import ContactUs from "./Components/Pages/Contact Us/ContactUs.js";
import Blog from "./Components/Pages/Blog/Blog.js";
import BlogDetail from "./Components/Pages/Blog/BlogDetails.js";
import Aboutus from "./Components/Pages/About US/Aboutus.js";
import OracleServices from "./Components/Pages/ServicesPages/Orcale/OracleServices.js";
import BusinessIntellegence from "./Components/Pages/ServicesPages/BuisnessIntellegnece/BusinessIntellegence.js";
import SAP from "./Components/Pages/ServicesPages/SAP/SAP.js";
import CustomerServices from "./Components/Pages/ServicesPages/CustomerServices/CustomerServices.js";
import AIandRPA from "./Components/Pages/ServicesPages/AI&RPA/AIandRPA.js";
import DigitalTransformation from "./Components/Pages/ServicesPages/DigitalTransformation/DigitalTransformation.js";
import CustomerManagement from "./Components/Pages/ServicesPages/CRM/CustomerManagement.js";

function App() {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/about-us' element={<Aboutus />} />
          <Route path='/blogs' element={<Blog />} />
          <Route path='/blog/:id' element={<BlogDetail />} />
          <Route path='/privacy&policy' element={<PrivacyPolicy />} />
          <Route path='/terms&condition' element={<TermsCondition />} />
          <Route path='/services/oracle' element={<OracleServices />} />
          <Route path='/services/businessintelligence' element={<BusinessIntellegence />} />
          <Route path='/services/sap' element={<SAP />} />
          <Route path='/services/customer-solution' element={<CustomerServices />} />
          <Route path='/services/ai-rpa' element={<AIandRPA />} />
          <Route path='/services/digital-transformation' element={<DigitalTransformation />} />
          <Route path='/services/crm' element={<CustomerManagement />} />
          <Route path='*' element={<UndefinePage />} />
        </Routes>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
