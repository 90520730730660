import React, { useRef, useState } from "react";
import "./feature.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroundImage from "../../../Assets/images/iamsignerBackground.svg";
import Oraclebackground from "../../../Assets/images/oracleUIbackground.svg";
import smsBackground from "../../../Assets/images/smsBackground.svg";
import uploadImage from "../../../Assets/images/Upload.svg";
import MacAiImage from "../../../Assets/images/MAcAi.svg";
import securityImage from "../../../Assets/images/shield-check.svg";
import ImageControl from "../../../Assets/images/ui-checks-grid.svg";
import EsignImage from "../../../Assets/images/Esign.svg";
import integrations from "../../../Assets/images/code-browser.svg";
import iamSignerLogo from "../../../Assets/images/IamsignerLogo.svg";
import OracleLogo from "../../../Assets/images/oracleUI.svg";
import OracleVector from "../../../Assets/images/OracleVector.svg";
import macquiresVector from "../../../Assets/images/MacquiresVector.svg";
import LaptopImage from "../../../Assets/images/Laptop.svg";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function SimpleSlider() {
  const sliderRef = useRef(null);
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [backgroundImage, smsBackground, Oraclebackground];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index);
    setCurrentSlide(index);
  };

  return (
    <div className='slider-container p-0 bg-[#f0f0f3] dark:bg-[#000410] text-[black] dark:text-white transition-colors duration-500'>
      <div className='button-container'>
        <button className={currentSlide === 0 ? "active" : ""} onClick={() => goToSlide(0)}>
          {t("home.eSignatureSolution")}
        </button>
        <div className='vertical-line'></div>
        <button className={currentSlide === 1 ? "active" : ""} onClick={() => goToSlide(1)}>
          {t("home.StrategyManagement")}
        </button>
        <div className='vertical-line'></div>
        <button className={currentSlide === 2 ? "active" : ""} onClick={() => goToSlide(2)}>
          {t("home.OracleUI")}
        </button>
        <div className='horizontal-line'></div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <div className='image-container'>
              <img src={image} alt={`Slide ${index + 1}`} className='background-images' />
              {index === 0 && (
                <>
                  <div className='overlay'>
                    <img src={iamSignerLogo} alt='IamSigner Logo' className='logo' />
                    <p className='text'>{t("home.EnhanceOrganizational")}</p>

                    <button className='learnMore'>{t("home.LearnMore")}</button>
                  </div>

                  <div className='icon-container'>
                    <div className='icon-box'>
                      <img src={uploadImage} alt='Upload' />
                      <p>{t("home.Upload")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={securityImage} alt='Security' />
                      <p>{t("home.Security")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={ImageControl} alt='Control' />
                      <p>{t("home.Control")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={EsignImage} alt='Sign' />
                      <p>{t("home.Sign")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={integrations} alt='Integration' />
                      <p>{t("home.Integration")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={MacAiImage} alt='MacAi' />
                      <p>{t("home.MacAi")}</p>
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div className='overlay'>
                    <h1>{t("home.sms")}</h1>
                    <p className='text'>{t("home.smsDes")}</p>

                    <button className='learnMore'>{t("home.LearnMore")}</button>
                  </div>

                  <div className='icon-container'>
                    <div className='icon-box'>
                      <img src={uploadImage} alt='Upload' />
                      <p>{t("home.Upload")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={securityImage} alt='Security' />
                      <p>{t("home.Security")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={ImageControl} alt='Control' />
                      <p>{t("home.Control")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={EsignImage} alt='Sign' />
                      <p>{t("home.Sign")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={integrations} alt='Integration' />
                      <p>{t("home.Integration")}</p>
                    </div>
                    <div className='icon-box'>
                      <img src={MacAiImage} alt='MacAi' />
                      <p>{t("home.MacAi")}</p>
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div className='overlayOracle'>
                    <img src={OracleLogo} alt='imageLoading' />

                    <div className='oracleImages'>
                      <div className='d-flex  gap-3'>
                        <h2>{t("home.EnginePowered")}</h2>
                        <img src={OracleVector} alt='Oracle' />
                      </div>
                      <div className='d-flex gap-3'>
                        <h2>{t("home.UiPowered")}</h2>
                        <img src={macquiresVector} alt='Macquries Ui' />
                      </div>
                    </div>
                    <img src={LaptopImage} alt='LaptopImage' className='md:w-[600px]' />
                  </div>

                  <div className='icon-container-oracle'>
                    <div className='icon-box-oracle'>
                      <label>{t("home.EmployeeDashboard")}</label>
                      <p>{t("home.EmployeeDashboardDes")}</p>
                    </div>
                    <div className='icon-box-oracle'>
                      <label>{t("home.Requests")}</label>
                      <p>{t("home.RequestsDes")}</p>
                    </div>
                    <div className='icon-box-oracle'>
                      <label>{t("home.ApprovalCycles")}</label>
                      <p>{t("home.ApprovalDes")}</p>
                    </div>
                    <div className='icon-box-oracle'>
                      <label>{t("home.EmployeeSelf")}</label>
                      <p>{t("home.EmployeeSelfDes")}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SimpleSlider;
