import React from "react";
import OrcaleImage from "../../../../Assets/images/Oracle services.svg";
import OrcaleLogo from "../../../../Assets/images/OracleServiesLogo.svg";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import "./OracleServices.css";
import { useTranslation } from "react-i18next";
import OrcaleSlider from "./OrcaleSlider";

function OracleServices() {
  const { t } = useTranslation();
  return (
    <div>
      <Header />

      <section className='oracle-section'>
        <div className='oracle-image-container'>
          <img src={OrcaleImage} alt='Oracle Logo' className='oracle-image' />
          <div className='overlay-oracle'>
            <img src={OrcaleLogo} alt='Orcale Logo' />
            <p className='overlay-subtext'>{t("Servives.Solutions")}</p>
          </div>
        </div>
      </section>

      <section className='small-overlay-section dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <div>
          <img src={OrcaleLogo} alt='Orcale Logo' />
          <p className='overlay-subtext'>{t("Servives.Solutions")}</p>
        </div>
      </section>

      <section className='OrcaleBusinessSection bg-[#f0f0f3] dark:bg-[#1b202c] dark:text-white transition-colors duration-500'>
        <div className='container flex flex-col gap-4'>
          <h1>{t("Servives.OracleBusiness")}</h1>
          <p className='mb-0'>{t("Servives.OracleBusinessDesc")}</p>
        </div>
      </section>

      <section className='OrcaleContentSection dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <div>
          <p>
            {t("Servives.OurExperts")} <br />
            {t("Servives.OracleSolutionProvider")}
          </p>
        </div>
      </section>

      <section className='OrcaleULSection dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <div className='AllSections'>
          <div className='threeSections'>
            <div className='MainUlSection'>
              <div className='UlSection'>
                <ul className='mb-0'>
                  <label className='fw-bold'>{t("Servives.MacquiresOrcale")}</label>
                  <li>{t("Servives.OracleFinancialManagement")}</li>
                  <li>{t("Servives.OracleSupplyChainManagement")}</li>
                  <li>{t("Servives.OracleHumanCapitalManagement")}</li>
                  <li>{t("Servives.OracleEnterprisePerformanceManagement")}</li>
                  <li>{t("Servives.OracleProjectPortfolioManagement")}</li>
                  <li>{t("Servives.OracleCustomerRelationship")}</li>
                  <li>{t("Servives.OracleBusinessIntelligenceApplications")}</li>
                </ul>
              </div>

              <div className='UlSection'>
                <ul className='mb-0'>
                  <label className='fw-bold'>{t("Servives.MacquiresPlatformSolutions")}</label>
                  <li>{t("Servives.OracleCloudTechnologySolution")}</li>
                  <li>{t("Servives.OracleTechnologySolution")}</li>
                  <li>{t("Servives.OracleApplicationExpress")}</li>
                  <li>{t("Servives.OracleBusinessIntelligence")}</li>
                  <li>{t("Servives.OracleProjectPortfolioManagement")}</li>
                  <li>{t("Servives.OracleMasterDataManagement")}</li>
                  <li>{t("Servives.OracleHyperion")}</li>
                </ul>
              </div>
            </div>

            <div className='UlSection'>
              <ul className='mb-0'>
                <label className='fw-bold'>{t("Servives.MacquiresOracleTechnologies")}</label>
                <li>
                  {t("Servives.CostEfficiency")}
                  <p>{t("Servives.ModernizingOracle")}</p>
                </li>
                <li>
                  {t("Servives.ResourceOptimization")}
                  <p>{t("Servives.MacquiresManage")}</p>
                </li>
                <li>
                  {t("Servives.Reliability")}
                  <p>{t("Servives.specialistsDeliver")}</p>
                </li>
                <li>
                  {t("Servives.AgilityInnovation")}
                  <p>{t("Servives.movingCloudNative")}</p>
                </li>
              </ul>
            </div>
          </div>

          <div className='VerticalContainer'>
            <ul className='mb-0'>
              <label className='fw-bold'>{t("Servives.MacquiresOracleServices")}</label>
              <li>{t("Servives.OracleApplicationModernization")}</li>
              <li>{t("Servives.OracleSystemAssessment")}</li>
              <li>{t("Servives.OracleInfrastructureManagingServices")}</li>
              <li>{t("Servives.OracleUpgradesService")}</li>
              <li>{t("Servives.OracleMigrationServices")}</li>
              <li>{t("Servives.OracleImplementation")}</li>
              <li>{t("Servives.OracleSystemImplementation")}</li>
              <li>{t("Servives.TestingService")}</li>
              <li>{t("Servives.OracleIntegration")}</li>
              <li>{t("Servives.DevelopmentOracleProjects")}</li>
              <li>{t("Servives.ProjectManagementService")}</li>
              <li>{t("Servives.OracleService")}</li>
              <li>{t("Servives.OracleSupportService")}</li>
              <li>{t("Servives.OraclePlatform")}</li>
              <li>{t("Servives.OracleSoftware")}</li>
            </ul>
          </div>
        </div>
      </section>

      <section className='ContentSlider'>
        <OrcaleSlider />
      </section>

      <Footer />
    </div>
  );
}

export default OracleServices;
