import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import webdark from "../../../Assets/images/BlogBusiness.svg";
import webLight from "../../../Assets/images/businesslight.svg";
import techdark from "../../../Assets/images/BlogTech.svg";
import techlight from "../../../Assets/images/weblight.svg";
import jumplight from "../../../Assets/images/jumplight.svg";
import jumpdark from "../../../Assets/images/jumpDarl.svg";
import OmarImage from "../../../Assets/images/UmerIamge.svg";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import "./blog.css";
import FloatingButton from "../../Floating Button/FloatingButton";

function BlogDetail() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  useEffect(() => {
    const checkDarkMode = () => {
      const darkModeEnabled = document.documentElement.classList.contains("dark");
      setIsDarkMode(darkModeEnabled);
    };

    checkDarkMode();

    const observer = new MutationObserver(() => {
      checkDarkMode();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const posts = [
    {
      image: isDarkMode ? webdark : webLight,
      title: t("Blog.MacquireDetailtitleBusiness"),
      description: t("Blog.MacquireDescription"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
      listItems: [t("Blog.OracleBusiness"), t("Blog.SAPBusiness"), t("Blog.CyberSecurityMicrosoft"), t("Blog.BIConsulting")],
    },
    {
      image: isDarkMode ? jumpdark : jumplight,
      title: t("Blog.MacquireDetailtitleBusiness"),
      description: t("Blog.MacquireDescription"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
      listItems: [t("Blog.OracleBusiness"), t("Blog.SAPBusiness"), t("Blog.CyberSecurityMicrosoft"), t("Blog.BIConsulting")],
    },
    {
      image: isDarkMode ? techdark : techlight,
      title: t("Blog.MacquireDetailtitleBusiness"),
      description: t("Blog.MacquireDescription"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
      listItems: [t("Blog.OracleBusiness"), t("Blog.SAPBusiness"), t("Blog.CyberSecurityMicrosoft"), t("Blog.BIConsulting")],
    },
  ];

  const post = posts[id];

  return (
    <div >
      <Header />
      <div>
        <img src={post.image} alt={`Post Detail`} className='full-screen-image' />
        <div className='blog-image-overlay   dark:text-white'>
          <h1 className='overlay-title'>{post.title}</h1>
        </div>
      </div>
      <div className='post-blogs dark:bg-[#000410] dark:text-white w-full'>
        <div className='post-breadcrumb dark:text-white'>
          <Link to='/' className='breadcrumb-link'>
            {t("Blog.home")}
          </Link>
          <Link to='/blog' className='breadcrumb-link'>
            {t("Blog.Blog")}
          </Link>
          <span>{post.title}</span>
        </div>
        <div className='post-author '>
          <img src={OmarImage} alt='Author' className='author-image' />
          <div className='author-info'>
            <p className='author-name mb-0'>{post.author}</p>
            <p className='post-date mb-0'>{post.date}</p>
          </div>
        </div>

        <div className='post-content-blog'>
          <p>{post.description}</p>
          <ul className='mx-3 '>
            {post.listItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <FloatingButton />
        </div>
      </div>
      <div className='glowContainer '>
        <div className=' text-center dark:bg-[#1b202c] bg-gray-200 dark:text-white' style={{ padding: "20px" }}>
          <div className='newsletter-container'>
            <h2>{t("Blog.StayInformed")}</h2>
            <h2>{t("Blog.Joinournewsletter")}</h2>
            <form onSubmit={handleSubmit(onSubmit)} className='newsletter-form'>
              <input type='email' placeholder='email@example.com' id='email' {...register("email", { required: true })} className='newsletter-input mb-0 dark:bg-[#1b202c] bg-gray-200' />
              <button type='submit' className='newsletter-button'>
                <FaArrowRight />
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BlogDetail;
