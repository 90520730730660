import React from "react";
import founderImage from "../../../Assets/images/threecolorBackground.svg";
import "./Founder.css";
import clientImage from "../../../Assets/images/Ellipse 9.svg";
import { RiDoubleQuotesL } from "react-icons/ri";
import { useTranslation } from "react-i18next";

function FounderSection() {
  const {t} =  useTranslation("")
  return (
    <div className='founder-section md:p-5 p-[20px] dark:bg-[#000410] text-[black] dark:text-white transition-colors duration-500'>
      <div className='d-flex justify-center mt-[3rem]'>
        <h1>{t("home.HereClientSaying")}</h1>
      </div>
      <div className='image-container-client'>
        <img src={founderImage} alt='Background' className='founder-image-background' />
        <div className='testimonial-card italic'>
          <div className='quote-icon'>
            <RiDoubleQuotesL />
          </div>
          <p className='testimonial-text italic'>{t("home.ClientDes")}</p>

          <div className='client-info'>
            <img src={clientImage} alt='Client' className='client-image' />
            <h3 className='client-name'>{t("home.JohnSmith")}</h3>
            <p className='client-title'>{t("home.FounderDes")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FounderSection;
