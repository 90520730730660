import React, { useState, useEffect } from "react";
import { CiMail } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import MacquiresLogo from "../../Assets/images/macquiresLogo.svg";
import MacquiresDark from "../../Assets/images/MacquiresDark.svg";
import linkedinImage from "../../Assets/images/linkedin.svg";
import linkedinDark from "../../Assets/images/linkedinDark.svg";
import facebookDark from "../../Assets/images/facebookDark.svg";
import facebookImage from "../../Assets/images/facebook.svg";
import twitterImage from "../../Assets/images/twitter.svg";
import twitterDark from "../../Assets/images/twitterDark.svg";
import Nav from "react-bootstrap/Nav";
import "./style.css";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const checkDarkMode = () => {
      const darkModeEnabled = document.documentElement.classList.contains("dark");
      setIsDarkMode(darkModeEnabled);
    };

    checkDarkMode();

    const observer = new MutationObserver(() => {
      checkDarkMode();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section id='footer' className='bg-gray-200 dark:bg-[#1b202c] text-gray-800 dark:text-white transition-colors duration-500'>
      <div className='main-footer'>
        <div className='logoinfo' data-aos='fade-up'>
          <img src={isDarkMode ? MacquiresDark : MacquiresLogo} alt='MacquiresLogo' />

          <div className='contact-details'>
            <label>
              {t("footer.MacquiresTechnology")}
              {t("footer.combinExpertise")}
              {t("footer.applications")}
              {t("footer.everythingWrapped")}
              {t("footer.successSo")}
            </label>
          </div>
          <div className='d-flex gap-3 mt-3'>
            <img src={isDarkMode ? linkedinDark : linkedinImage} alt='MacquiresLogo' style={{ height: "30px" }} />
            <img src={isDarkMode ? facebookDark : facebookImage} alt='MacquiresLogo' style={{ height: "30px" }} />
            <img src={isDarkMode ? twitterDark : twitterImage} alt='MacquiresLogo' style={{ height: "30px" }} />
          </div>
        </div>
        <div className='MainMenu' data-aos='fade-up'>
          <h1>{t("footer.mainMenu")}</h1>
          <ul>
            <li>
              <Nav.Link to='/' as={Link} className={`navLinksfooterhome ${location.pathname === "/" ? "active" : ""}`}>
                {t("footer.home")}
              </Nav.Link>
            </li>
            <li>
              <Nav.Link to='/services' as={Link} className={`navLinksfooterServices ${location.pathname === "/services" ? "active" : ""}`}>
                {t("footer.services")}
              </Nav.Link>
            </li>
            <li>
              <Nav.Link to='/about-us' as={Link} className={`navLinksfooterAbout ${location.pathname === "/about-us" ? "active" : ""}`}>
                {t("footer.aboutUs")}
              </Nav.Link>
            </li>
            <li>
              <Nav.Link to='/contact-us' as={Link} className={`navLinksfooterContactus ${location.pathname === "/contact-us" ? "active" : ""}`}>
                {t("footer.contactus")}
              </Nav.Link>
            </li>
          </ul>
        </div>
        <div className='MainMenu' data-aos='fade-up'>
          <h1>{t("footer.product")}</h1>
          <ul>
            <li>
              <label>IamSigner</label>
            </li>
            <li>
              <label>SMS</label>
            </li>
          </ul>
        </div>

        <div className='info' data-aos='fade-up'>
          <h1> {t("footer.contactus")}</h1>
          <div className='logobox'>
            <div className='d-flex gap-2 align-items-start'>
              <div>
                <span>{t("footer.Riyadh")}</span>
                <br />
                <span>+966 50 006 7158</span>
                <br />
                <span>+966 50 017 2382</span>
              </div>
            </div>
            <div className='d-flex gap-2 align-items-start'>
              <div>
                <span>{t("footer.Amman")}</span>
                <br />
                <span>+962 79 111 1282</span>
              </div>
            </div>
            <div className='d-flex gap-2 align-items-start'>
              <div>
                <span>{t("footer.Islamabad")}</span>
                <br />
                <span>+92 343 9650517</span>
              </div>
            </div>
            <div className='d-flex gap-2 align-items-start'>
              <CiMail size={22} />
              <span>Info@macquires.com</span>
            </div>
          </div>
        </div>

        <div className='MainMenu' data-aos='fade-up'>
          <h1>{t("footer.help")}</h1>
          <ul>
            <li>
              <Nav.Link to='/terms&condition' as={Link} className={`navLinksfooter ${location.pathname === "/terms&condition" ? "active" : ""}`}>
                <label>{t("footer.termConditions")}</label>
              </Nav.Link>
            </li>
            <li>
              <Nav.Link to='/privacy&policy' as={Link} className={`navLinksfooterPrivacy ${location.pathname === "/privacy&policy" ? "active" : ""}`}>
                <label>{t("footer.privacyPolicy")}</label>
              </Nav.Link>
            </li>
          </ul>
        </div>
      </div>
      <footer>{t("footer.rights")}</footer>
    </section>
  );
};

export default Footer;
