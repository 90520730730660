import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import realstateImage from "../../../Assets/images/homeIcon.svg";
import communicationImage from "../../../Assets/images/communication.svg";
import automationImage from "../../../Assets/images/automation.svg";
import governmentImage from "../../../Assets/images/government.svg";
import retailImage from "../../../Assets/images/retail.svg";
import manufactureImage from "../../../Assets/images/manufacturing.svg";
import energyImage from "../../../Assets/images/energy.svg";
import nonprofitImage from "../../../Assets/images/nonprofit.svg";
import educationImage from "../../../Assets/images/education.svg";
import healthCareImage from "../../../Assets/images/healthCare.svg";
import "./AutoPlay.css";
import { useTranslation } from "react-i18next";

function AutoPlay() {
  const { t } = useTranslation();

  const settings = {
    infinite: true,
    slidesToShow: 5,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    rows: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settingsLTR = {
    infinite: true,
    slidesToShow: 5,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    rows: 1,
    rtl: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const services = [
    { src: realstateImage, title: t("home.RealEstate") },
    { src: communicationImage, title: t("home.CommunicationMedia") },
    { src: automationImage, title: t("home.Automation") },
    { src: governmentImage, title: t("home.Government") },
    { src: retailImage, title: t("home.Retail") },
  ];

  const servicesLTR = [
    { src: manufactureImage, title: t("home.Manufacturing") },
    { src: energyImage, title: t("home.Energy") },
    { src: nonprofitImage, title: t("home.Non-ProfitOrganization") },
    { src: educationImage, title: t("home.Education") },
    { src: healthCareImage, title: t("home.HealthCare") },
  ];

  return (
    <div className='slider-container md:p-5 dark:bg-[#000410] text-[black] dark:text-white transition-colors duration-500'>
      <Slider {...settings}>
        {services.map((service, index) => (
          <div key={index} className='service-slide'>
            <div className='service-content bg-[#f0f0f3] dark:bg-[#1b202c] text-[black] dark:text-white transition-colors duration-500'>
              <img src={service.src} alt={service.title} className='service-image' />
              <h3 className='service-title'>{service.title}</h3>
            </div>
          </div>
        ))}
      </Slider>
      <Slider {...settingsLTR}>
        {servicesLTR.map((service, index) => (
          <div key={index} className='service-slide'>
            <div className='service-content bg-[#f0f0f3] dark:bg-[#1b202c] text-[black] dark:text-white transition-colors duration-500'>
              <img src={service.src} alt={service.title} className='service-image' />
              <h3 className='service-title'>{service.title}</h3>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default AutoPlay;
