import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    document.documentElement.dir = newLanguage === "ar" ? "rtl" : "ltr";
  };

  return (
    <button className=' dark:text-white text-gray-500 mb-2' onClick={toggleLanguage}>
      {currentLanguage === "en" ? t("languageSelector.arabic") : t("languageSelector.english")}
    </button>
  );
}

export default LanguageSelector;
