  import React from "react";
import Header from "../../Header/Header";
import blackHole from "../../../Assets/images/black-hole.gif";
import "./home.css";
import ImageSection from "./ImageSection";
import AutoPlay from "./AutoPlay";
import Footer from "../../Footer/Footer";
import Featured from "./Featured";
import FounderSection from "./FounderSection";
import RecentPost from "./RecentPost";
import SwiperComponent from "./SwiperComponent";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />

      <section className='Mainsection sm:text-black md:bg-[#000410] md:text-white'>
        <div className='md:w-[29rem] sm:[10rem] contentSection'>
          <h1>{t("home.Focused")}</h1>
          <p>{t("home.MacquiresTeam")}</p>
          <button className='pulse'>{t("home.ReachOut")}</button>
        </div>
        <div>
          <img src={blackHole} alt='Gif Loading' />
        </div>
      </section>

      <section className='section-wrapper '>
        <div className='bg-gray-200  dark:bg-[#1b202c] dark:text-white transition-colors duration-500'>
          <div className='secondSection'>
            <h1>{t("home.WhoWeAre")}</h1>
            <p className='md:w-[50rem] m-auto md:text-center text-start'>{t("home.MacquiresTechnology")} </p>
          </div>
        </div>
      </section>

      <section>
        <SwiperComponent />
      </section>

      <section>
        <ImageSection />
      </section>

      <section>
        <div className='secondSection md:text-center dark:bg-[#000410] dark:text-white transition-colors duration-500'>
          <h1>{t("home.ExploreMacquires")}</h1>
          <p>{t("home.ExploreBusiness")}</p>
        </div>
      </section>

      <section>
        <AutoPlay />
      </section>

      <section>
        <Featured />
      </section>

      <section>
        <FounderSection />
      </section>

      <section>
        <RecentPost />
      </section>

      <Footer />
    </div>
  );
}

export default Home;
