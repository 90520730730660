import React, { useEffect } from "react";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import IntegrationImage from "../../../Assets/images/Integration.svg";
import digitalTransformation from "../../../Assets/images/DigitalTransformation.svg";
import oracleImage from "../../../Assets/images/oracle.svg";
import customerServices from "../../../Assets/images/customerSolution.svg";
import crmServices from "../../../Assets/images/crm Services.svg";
import biSolution from "../../../Assets/images/Bi solution.svg";
import dotNetImage from "../../../Assets/images/dotNet.svg";
import "./swiper.css";
import { useTranslation } from "react-i18next";

const SwiperComponent = () => {
  const { t } = useTranslation();

  useEffect(() => {
    new Swiper(".mySwiper", {
      effect: "coverflow",
      grabCursor: true,
      navigation: true,
      centeredSlides: true,
      loop: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 150,
        modifier: 2.5,
      },
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false,
      // },
    });
  }, []);

  const images = [
    { src: IntegrationImage, alt: "Integration", title: t("home.Integration"), description: t("home.IntegrationDes") },
    {
      src: digitalTransformation,
      alt: "Digital Transformation",
      title: t("home.DigitalTransformation"),
      description: t("home.DigitalTransformationDes"),
    },
    { src: oracleImage, alt: "Oracle", title: t("home.OracleServices"), description: t("home.OracleServicesDes") },
    {
      src: customerServices,
      alt: "Customer Solutions",
      title: t("home.CustomerSolutions"),
      description: t("home.CustomerSolutionsDes"),
    },
    { src: crmServices, alt: "CRM Services", title: t("home.CRMServices"), description: t("home.CRMServicesDes") },
    { src: biSolution, alt: "BI Solution", title: t("home.BISolution"), description: t("home.BISolutionDes") },
    { src: dotNetImage, alt: "Dot Net", title: t("home.net"), description: t("home.netDes") },
  ];

  return (
    <div className='md:p-5 p-[20px] dark:bg-[#000410] dark:text-white transition-colors duration-500'>
      <h1 className='text-center py-[5rem]'>{t("home.providedServices")}</h1>
      <section className='collection '>
        <div className='swiper mySwiper swiper-carousel swiper-initialized swiper-horizontal swiper-watch-progress'>
          <div className='swiper-wrapper'>
            {images.map((image, index) => (
              <div className='content swiper-slide' key={index}>
                <img src={image.src} alt={image.alt} />
                <div className='overlaySwiper'>
                  <div className='text-content'>
                    <h3>{image.title}</h3>
                    <p>{image.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SwiperComponent;
