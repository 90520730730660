import React from "react";
import { useTranslation } from "react-i18next";
import CrmImage from "../../../../Assets/images/CustomerRM services.svg";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import "./crm.css";

function CustomerManagement() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <section className='bg-image-section'>
        <div className='crm-image-container'>
          <img src={CrmImage} alt='customer relation' className='crm-image' />

          <div className='overlay-crm'>
            <h1>{t("CRM.CRM")}</h1>
          </div>
        </div>
      </section>
      <section>
        <div className='crm-second-section bg-gray-100 dark:bg-[#1b202c] dark:text-white transition-colors duration-500'>
          <p>{t("CRM.MaximizeProfitability")}</p>
          <p>{t("CRM.UnlockfullPotential")}</p>
        </div>
      </section>


      <section className='Bi-content-section bg-[#fff] dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <div className='Bi-content-Child'>
          <div className='Bi-subChild'>
            <h1>{t("CRM.ToGive")}</h1>
            <p>{t("CRM.InsightHelps")}</p>
          </div>

          <div className='Bi-child-ulSection'>
            <div className='Bi-customer'>
              <label className='fw-bold'>{t("CRM.Marketingjourney")}</label>
              <p>{t("CRM.GenerateMultichannel")}</p>
            </div>
            <hr />
            <div className='Bi-customer'>
              <label className='fw-bold'>{t("CRM.SaleEmpower")}</label>
              <p>{t("CRM.GoBeyondSales")}</p>
            </div>
            <hr />
            <div className='Bi-customer'>
              <ul>
                <label className='fw-bold'>{t("CRM.MacquiresHelp")}.</label>
                <li>{t("CRM.Achieve")}</li>
                <li>{t("CRM.MakeBetter")}</li>
                <li>{t("CRM.MaximizeInvestment")}</li>
                <li>{t("CRM.LeverageExperts")}</li>
              </ul>
            </div>
            <hr />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CustomerManagement;
