import React, { useState } from "react";
import { Fab, Zoom } from "@mui/material";
import { LinkedIn as LinkedInIcon, Email as AttachEmailIcon, Share as ShareIcon } from "@mui/icons-material";
import "./style.css";

function FloatingButton() {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className='fab-container'>
      <Zoom in={open}>
        <Fab className='actionButton' aria-label='Email'>
          <AttachEmailIcon />
        </Fab>
      </Zoom>
      <Zoom in={open}>
        <Fab className='actionButton' aria-label='Linkedin'>
          <LinkedInIcon />
        </Fab>
      </Zoom>
      <Fab className='mainFab' aria-label='Share' onClick={handleToggle}>
        <ShareIcon />
      </Fab>
    </div>
  );
}

export default FloatingButton;
