import React from "react";
import HumanTechImage from "../../../Assets/images/Technology Human.svg";
import TechOverlay from "../../../Assets/images/Overlay Technology.svg";
import BusinessImage from "../../../Assets/images/AboutusBusiness.svg";
import MountainImage from "../../../Assets/images/DrivingMountain.svg";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import AboutBg from "../../../Assets/images/aboutusmatch.svg";
import "./style.css";
import Partners from "./Partners";
import { useTranslation } from "react-i18next";

function Aboutus() {
  const { t } = useTranslation();
  return (
    <div>
      <Header />
      <div className='image-container'>
        <img src={HumanTechImage} alt='Human Technology' className='full-screen-image-aboutus' />
        <div className='aboutus-image-overlay'>
          <img src={TechOverlay} alt='Technology' className='full-screen-image-aboutus' />
        </div>
        <div className='about-image-overlay'>
          <h1 className='overlay-title-about'>{t("aboutus.MacquiresPartner")}</h1>
          <p className='overlay-title-about'>{t("aboutus.MacquiresTechnologyConsulting")}</p>
        </div>
      </div>

      <div className='OurMission dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <div className='MissionContent'>
          <ul>
            <h2>{t("aboutus.OurMission")}</h2>
            <li>{t("aboutus.AtMacquires")}</li>
            <li>{t("aboutus.SpecialistsAccelerate")}</li>
          </ul>
        </div>
        <div>
          <img src={BusinessImage} alt='Our Mission' />
        </div>
      </div>

      <div className='OurVission bg-gray-200  dark:bg-[#1b202c] dark:text-white transition-colors duration-500'>
        <div>
          <img src={MountainImage} alt='Our Mission' />
        </div>
        <div className='VissionContent'>
          <ul>
            <h2>{t("aboutus.OurVision")}</h2>
            <li>{t("aboutus.TrustedCompany")}</li>
          </ul>
        </div>
      </div>

      <div className='image-container '>
        <img src={AboutBg} alt='About us' className='full-screen-AboutLast' />
        <div className='about-bg-overlay'>
          <div className='overlay-title-about'>
            <h1>{t("aboutus.ourApproach")}</h1>
            <ul className='text-start'>
              <li>{t("aboutus.AtMacquires")}</li>
              <li>{t("aboutus.SpecialistsAccelerate")}</li>
            </ul>
            <p className='text-start'>{t("aboutus.ToBecomemostTrusted")}</p>
          </div>
        </div>
      </div>
      <section>
        <Partners />
      </section>
      <Footer />
    </div>
  );
}

export default Aboutus;
