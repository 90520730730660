import React, { useEffect, useState } from "react";
import ReactSwitch from "react-switch";

const ThemeToggle = ({ defaultTheme }) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || defaultTheme); 

  useEffect(() => {
    document.documentElement.className = theme;
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <div>
      <ReactSwitch
        checked={theme === "dark"}
        onChange={toggleTheme}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor='#fff'
        offColor='#0F3457'
        onHandleColor='#F2652A'
        offHandleColor='#F2652A'
        height={30}
        width={50}
        handleDiameter={20}
      />
    </div>
  );
};

export default ThemeToggle;
