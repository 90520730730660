import React, { useState, useEffect } from "react";
import privayImageMobile from "../../../Assets/images/privacyMobile.svg";
import privayImage from "../../../Assets/images/Privacy Policy.svg";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import "./privacy.css";

function PrivacyPolicy() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className='privacy-container'>
        <img src={isMobile ? privayImageMobile : privayImage} alt='privacy policy' className='privacy-image' />
        <div className='PrivacyOverlay'>
          <p>Current as of Jan 01, 2024</p>
          <h1>Privacy Policy</h1>
          <p>
            Welcome to Macquires Technology Consulting! By accessing our website and utilizing our services, you agree to comply with and be bound by the following terms and conditions of use. Please
            read these terms carefully before proceeding to use our website.
          </p>
        </div>
      </div>
      <div className="dark:bg-[#000410] dark:text-white transition-colors duration-500'">
        <div className='flex flex-col justify-center p-[20px] md:p-[5rem] md:w-[80%] m-auto'>
          <div>
            <h3>1. Solutions Expert Leadership: </h3>
            <p>
              Macquires Technology Consulting leads the industry as a solutions expert, seamlessly integrating our expertise with cutting-edge technologies spanning applications, data, and security.
              We are committed to delivering end-to-end solutions that empower our clients to thrive in an ever-evolving digital landscape.
            </p>
          </div>
          <div>
            <h3>2. Global Technology Services: </h3>
            <p>
              As a global technology services provider, Macquires Technology Consulting offers innovative capabilities to assist clients in building new revenue streams, optimizing efficiency, and
              creating exceptional experiences. Our dedication to delivering transformative solutions is at the core of everything we do.{" "}
            </p>
          </div>
          <div>
            <h3>3. Customer Success Obsession: </h3>
            <p>
              At Macquires, our relentless focus on customer success drives our every endeavor. We are dedicated to enabling our clients to work faster, smarter, and stay ahead of emerging trends and
              challenges.
            </p>
          </div>
          <div>
            <h3>4. Website Usage: </h3>
            <p>
              Your use of our website signifies your agreement to these Terms. We urge you to navigate our website responsibly and refrain from engaging in any activities that may violate these Terms
              or compromise the integrity of our services.
            </p>
          </div>
          <div>
            <h3>5. Updates and Modifications: </h3>
            <p>
              Macquires Technology Consulting reserves the right to update or modify these Terms at any time without prior notice. Changes to these Terms will be effective immediately upon posting on
              our website. It is your responsibility to review these Terms periodically for any updates or changes.{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
