import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BusinessIntellegenceImg from "../../../../Assets/images/BuisnessIntellegence services.svg";
import BILogo from "../../../../Assets/images/Business Intelligence Logo.svg";
import BILogoDark from "../../../../Assets/images/BiLogoDark.svg";
import "./BI.css";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";

function BusinessIntellegence() {
  const { t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const checkDarkMode = () => {
      const darkModeEnabled = document.documentElement.classList.contains("dark");
      setIsDarkMode(darkModeEnabled);
    };

    checkDarkMode();

    const observer = new MutationObserver(() => {
      checkDarkMode();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div>
      <Header />
      <section className='BI-section'>
        <div className='BI-image-container'>
          <img src={BusinessIntellegenceImg} alt='Business Intellegence' className='oracle-image' />
          <div className='overlay-Bi'>
            <img src={BILogo} alt='BI Logo' className='BI-Logo' />
            <p className='BI-overlay-subtext'>{t("BiIntellegence.BusinessIntelligenceOptimizes")}</p>
          </div>
        </div>
      </section>

      <section className='Sm-Bi-section dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <div>
          <img src={isDarkMode ? BILogo : BILogoDark} alt='Orcale Logo'  />

          <p className='overlay-subtext'>{t("BiIntellegence.BusinessIntelligenceOptimizes")}</p>
        </div>
      </section>

      <section className='Bi-content-section dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <div className='Bi-content-Child'>
          <div className='Bi-subChild'>
            <h1>{t("BiIntellegence.BiDesc")}</h1>
            <p>{t("BiIntellegence.CreateComprehensive")}</p>
            <p>{t("BiIntellegence.BusinessIntelligenceEncompasses")}</p>
          </div>

          <div className='Bi-child-ulSection'>
            <div className='Bi-customer'>
              <ul>
                <label className='fw-bold'>{t("BiIntellegence.CustomerExpectations")}</label>
                <li>{t("BiIntellegence.OracleBusiness")}</li>
                <li>{t("BiIntellegence.SAPBusiness")}</li>
                <li>{t("BiIntellegence.MicrosoftBusiness")}</li>
                <li>{t("BiIntellegence.BIConsultingServices")}</li>
              </ul>
            </div>
            <hr />
            <div className='Bi-customer'>
              <ul>
                <label className='fw-bold'>{t("BiIntellegence.MacquiresCanHelp")}.</label>
                <li>{t("BiIntellegence.IdentifyAreas")}</li>
                <li>{t("BiIntellegence.AnalyzeCustomer")}</li>
                <li>{t("BiIntellegence.CompareData")}</li>
                <li>{t("BiIntellegence.TrackCompany")}</li>
                <li>{t("BiIntellegence.OptimizeCompany")}</li>
                <li>{t("BiIntellegence.PredictSuccess")}</li>
                <li>{t("BiIntellegence.IdentifyMarket")}</li>
                <li>{t("BiIntellegence.IdentifyBusiness")}</li>
                <li>{t("BiIntellegence.ExpertiseDemand")}</li>
                <li>{t("BiIntellegence.CustomizedSolutions")}</li>
              </ul>
            </div>

            <hr />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default BusinessIntellegence;
