import React, { useState, useEffect } from "react";
import miscrosoftImage from "../../../Assets/images/MicrosoftLight.svg";
import miscrosoftDark from "../../../Assets/images/MicosoftDark.svg";
import DataSentialImage from "../../../Assets/images/DataSentailLight.svg";
import DataSentialDark from "../../../Assets/images/DataSentialDark.svg";
import informaticImage from "../../../Assets/images/InformaticLight.svg";
import informaticDark from "../../../Assets/images/InformaticaDark.svg";
import DelpixImage from "../../../Assets/images/DelphixLight.svg";
import DelpixDark from "../../../Assets/images/DelphixDark.svg";
import SolarWindImage from "../../../Assets/images/SolarWindsLight.svg";
import SolarWindDark from "../../../Assets/images/SolarWindDark.svg";
import DeLLEMCImage from "../../../Assets/images/DellLight.svg";
import DeLLEMCDark from "../../../Assets/images/DellEmcDark.svg";
import DataxImage from "../../../Assets/images/OataxLight.svg";
import DataxDark from "../../../Assets/images/DataxDark.svg";
import Dbvisit from "../../../Assets/images/DbVisitLight.svg";
import quest from "../../../Assets/images/QuestLight.svg";
import vmware from "../../../Assets/images/VmwareLight.svg";
import veeam from "../../../Assets/images/VeeamLight.svg";
import veeamDark from "../../../Assets/images/VeaamDark.svg";
import metricImage from "../../../Assets/images/MetricStreamLight.svg";
import metricDark from "../../../Assets/images/MatricStreamDark.svg";
import Hewlettpackard from "../../../Assets/images/HewlettLight.svg";
import HewlettDark from "../../../Assets/images/HewelletDark.svg";
import veritas from "../../../Assets/images/VeritasLight.svg";
import HVR from "../../../Assets/images/HVRLight.svg";
import HVRDark from "../../../Assets/images/HVRDark.svg";
import "./style.css";

function Partners() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const checkDarkMode = () => {
      const darkModeEnabled = document.documentElement.classList.contains("dark");
      setIsDarkMode(darkModeEnabled);
    };

    checkDarkMode();

    const observer = new MutationObserver(() => {
      checkDarkMode();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const images = [
    { light: miscrosoftImage, dark: miscrosoftDark, alt: "Microsoft" },
    { light: DataSentialImage, dark: DataSentialDark, alt: "Data Sential" },
    { light: DelpixImage, dark: DelpixDark, alt: "Delphix" },
    { light: informaticImage, dark: informaticDark, alt: "Informatic" },
    { light: SolarWindImage, dark: SolarWindDark, alt: "Solar Winds" },
    { light: DataxImage, dark: DataxDark, alt: "Oatax" },
    { light: DeLLEMCImage, dark: DeLLEMCDark, alt: "Dell EMC" },
    { light: Dbvisit, dark: Dbvisit, alt: "Dbvisit" },
    { light: quest, dark: quest, alt: "Quest" },
    { light: vmware, dark: vmware, alt: "VMware" },
    { light: veeam, dark: veeamDark, alt: "Veeam" },
    { light: metricImage, dark: metricDark, alt: "Metric Stream" },
    { light: Hewlettpackard, dark: HewlettDark, alt: "Hewlett Packard" },
    { light: veritas, dark: veritas, alt: "Veritas" },
    { light: HVR, dark: HVRDark, alt: "HVR" },
  ];

  return (
    <div>
      <section className='dark:bg-[#000410] text-[black] dark:text-white transition-colors duration-500'>
        <div className='text-center md:p-5'>
          <h1 className='md:py-[5rem] py-5'>Partnerships with 30+ IT Vendors</h1>
          <div className='d-flex align-items-center justify-content-between flex-wrap h-[100vh] md:w-[80%] m-auto  md:gap-12  p-[20px]'>
            {images.map((image, index) => (
              <img key={index} src={isDarkMode ? image.dark : image.light} alt={image.alt} className='aboutuspaetnerImage bg-[#f0f0f3] dark:bg-[#1b202c] transition-colors duration-500' />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Partners;
