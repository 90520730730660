import React, { useState, useEffect } from "react";
import industrialImage from "../../../Assets/images/industrialCenter.svg";
import MinistryEconomyImage from "../../../Assets/images/MinistryEconomy.svg";
import realStateImage from "../../../Assets/images/realState.svg";
import generalorganizationSocialImage from "../../../Assets/images/generalorganizationSocial.svg";
import CenterofGoveranceImage from "../../../Assets/images/CenterofGoverance.svg";
import kavaraImageLight from "../../../Assets/images/kavaraLight.svg";
import kavaraImagedark from "../../../Assets/images/KavaraDark.svg";
import nadecImage from "../../../Assets/images/nadec.svg";
import DarNakImage from "../../../Assets/images/daralkaran.svg";
import DarNakImageDark from "../../../Assets/images/darAlkranDark.svg";
import abdulLateefImage from "../../../Assets/images/abdullatif jameel.svg";
import abdulLateefImageDark from "../../../Assets/images/AbdulLatiifDark.svg";
import quaraImage from "../../../Assets/images/Quara.svg";
import quaraImageDark from "../../../Assets/images/Quaradark (2).svg";
import DolceGabana from "../../../Assets/images/Dolce&gabana.svg";
import DolceGabanaDark from "../../../Assets/images/Group 1000004870.svg";
import ElieImage from "../../../Assets/images/ElieSab.svg";
import ElieImageDark from "../../../Assets/images/ElieDark.svg";
import MissoniImage from "../../../Assets/images/Missoni.svg";
import MissoniImageDark from "../../../Assets/images/MissoniDark.svg";
import BentlyImahe from "../../../Assets/images/Bentley.svg";
import BentlyImaheDark from "../../../Assets/images/BentlyDark.svg";
import TilalImage from "../../../Assets/images/Tilal.svg";
import TilalImageDark from "../../../Assets/images/Tialdark.svg";
import robertoImage from "../../../Assets/images/Roberto.svg";
import robertoImageDark from "../../../Assets/images/RobertoDark.svg";
import versaceImage from "../../../Assets/images/versace.svg";
import versaceImageDark from "../../../Assets/images/VersaceDark.svg";
import AlmooseImage from "../../../Assets/images/AlmoosaHospital.svg";
import QasaeImage from "../../../Assets/images/QasarMall.svg";
import WqSaltImage from "../../../Assets/images/qwSalt.svg";
import WqSaltImageDark from "../../../Assets/images/QasraMalDark.svg";
import DarGlobalImage from "../../../Assets/images/Darglobal.svg";
import DarGlobalImageDark from "../../../Assets/images/DarglobalDark.svg";
import firstChoisImage from "../../../Assets/images/firstchoice.svg";
import firstChoisImageDark from "../../../Assets/images/firstchoiseDark.svg";
import IbdaImage from "../../../Assets/images/Ibda.svg";
import Biyotobiya from "../../../Assets/images/Biyotubia.svg";
import "./home.css";
import { useTranslation } from "react-i18next";

function ImageSection() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const {t} = useTranslation()
  
  useEffect(() => {
    const checkDarkMode = () => {
      const darkModeEnabled = document.documentElement.classList.contains("dark");
      setIsDarkMode(darkModeEnabled);
    };

    checkDarkMode();

    const observer = new MutationObserver(() => {
      checkDarkMode();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);
  return (
    <div>
      <section className='dark:bg-[#000410] text-[black] dark:text-white transition-colors duration-500'>
        <div className='text-center  md:p-5'>
          <h1 className='md:py-[5rem] py-2'>{t("home.OurClients")}</h1>
          <div className='d-flex align-items-center justify-content-between flex-wrap gap-3 md:gap-12 md:px-[8rem] p-[20px]'>
            <img src={MinistryEconomyImage} alt='ImageLoading' className='ImageSection' />
            <img src={CenterofGoveranceImage} alt='ImageLoading' className='ImageSection' />
            <img src={generalorganizationSocialImage} alt='ImageLoading' className='ImageSection' />
            <img src={realStateImage} alt='ImageLoading' className='ImageSection' />
            <img src={industrialImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? kavaraImageLight : kavaraImagedark} alt='ImageLoading' className='ImageSection' />
            <img src={nadecImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? DarNakImageDark : DarNakImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? abdulLateefImageDark : abdulLateefImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? quaraImageDark : quaraImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? DolceGabanaDark : DolceGabana} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? ElieImageDark : ElieImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? MissoniImageDark : MissoniImage} alt='ImageLoading' className='ImageSection' />

            <img src={isDarkMode ? BentlyImaheDark : BentlyImahe} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? TilalImageDark : TilalImage} alt='ImageLoading' className='ImageSection' />

            <img src={isDarkMode ? robertoImageDark : robertoImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? versaceImageDark : versaceImage} alt='ImageLoading' className='ImageSection' />

            <img src={AlmooseImage} alt='ImageLoading' className='ImageSection' />
            <img src={QasaeImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? WqSaltImageDark : WqSaltImage} alt='ImageLoading' className='ImageSection' />
            <img src={isDarkMode ? DarGlobalImageDark : DarGlobalImage} alt='ImageLoading' className='ImageSection' />

            <img src={isDarkMode ? firstChoisImageDark : firstChoisImage} alt='ImageLoading' className='ImageSection' />
            <img src={IbdaImage} alt='ImageLoading' className='ImageSection' />
            <img src={Biyotobiya} alt='ImageLoading' className='ImageSection' />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ImageSection;
