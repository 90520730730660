import React from "react";
import DigitalTransformationIamge from "../../../../Assets/images/DigitalTransformation services.svg";
import "./Digital.css";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import { useTranslation } from "react-i18next";

function DigitalTransformation() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />

      <section>
        <img src={DigitalTransformationIamge} alt='DigitalTransformation' className='Digitalimage' />
      </section>

      <section className='DigitalSection dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <h1 className='Digi-subtext'>{t("DigitalTransformation.DigitalTransformation")}</h1>
        <h2>{t("DigitalTransformation.DTDesc")}</h2>
        <p>{t("DigitalTransformation.TransformDigital")}</p>
        <p>{t("DigitalTransformation.MacquiresDigital")}</p>
      </section>

      <section className='DigitalTransformation bg-[#fbfbfb] dark:bg-[#000410] transition-colors duration-500 dark:text-white'>
        <div className='Digital-child bg-[#fff]  dark:bg-[#1b202c]'>
          <label>{t("DigitalTransformation.BusinessActivitiesFunctions")}</label>
        </div>
        <div className='Digital-child bg-[#fff]  dark:bg-[#1b202c]'>
          <label>{t("DigitalTransformation.BusinessProcesses")}</label>
        </div>
        <div className='Digital-child bg-[#fff]  dark:bg-[#1b202c] '>
          <label>{t("DigitalTransformation.BusinessModels")}</label>
        </div>
        <div className='Digital-child bg-[#fff]  dark:bg-[#1b202c] '>
          <label>{t("DigitalTransformation.BusinessEcosystems")}</label>
        </div>
        <div className='Digital-child bg-[#fff]  dark:bg-[#1b202c] '>
          <label>{t("DigitalTransformation.OrganizationalCulture")}</label>
        </div>
      </section>

      
      <Footer />
    </div>
  );
}

export default DigitalTransformation;
