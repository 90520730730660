import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./style.css";

function UndefinePage() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className='dark:bg-[#000410] dark:text-white transition-colors duration-500 '>
        <div className='contentBackground'>
          <div className='undefinePage h-[100vh]  md:w-[40%] m-auto flex flex-col items-center justify-center p-[20px]'>
            <h1 className='md:text-[10rem] font-bold text-[#FF6D37]'>404</h1>
            <h2 className='md:text-[3rem] text-2xl font-bold '>Page Not Found</h2>
            <p className='md:text-lg text-center mt-4'>The page you're looking for can't be found. Double-check the URL and try again. Or click the button below.</p>
            <button
              className='pulse bg-white text-black'
              onClick={() => {
                navigate("/");
              }}
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UndefinePage;
