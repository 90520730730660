import React from "react";
import "./Customer.css";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import CustomerServicesImage from "../../../../Assets/images/Customer services.svg";
import { useTranslation } from "react-i18next";

function CustomerServices() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <section>
        <img src={CustomerServicesImage} alt='Customer Services' className='CMSImage' />
      </section>
      <section className='secondSectionCM dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <h1>{t("CustomerService.MacquiresTechnology")}</h1>
        <p>{t("CustomerService.Consulting")}</p>
        <p>{t("CustomerService.Wespecialize")}</p>
      </section>
      <Footer />
    </div>
  );
}

export default CustomerServices;
