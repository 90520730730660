import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MacquiresLogo from "../../Assets/images/macquiresLogo.svg";
import MacquiresDark from "../../Assets/images/MacquiresDark.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import "./style.css";
import ThemeToggle from "../DarkMode/ThemeToggle";
import LanguageSelector from "../Language/LanguageSelector";
import iamsignerLogo from "../../Assets/images/iamsignerProduct.svg";
import smsLogo from "../../Assets/images/smsProduct.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [active, setActive] = useState(false);
  const [serviceActive, setServiceActive] = useState(false);

  const handleDropdownClick = () => {
    setActive(!active);
  };
  const handleDropdownClickServices = () => {
    setServiceActive(!serviceActive);
  };

  useEffect(() => {
    const checkDarkMode = () => {
      const darkModeEnabled = document.documentElement.classList.contains("dark");
      setIsDarkMode(darkModeEnabled);
    };

    checkDarkMode();

    const observer = new MutationObserver(() => {
      checkDarkMode();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const expand = "lg";

  const ServicesNames = [
    { name: "Oracle Services", link: "/services/oracle" },
    { name: "BI Solution", link: "/services/businessintelligence" },
    { name: "CRM Services", link: "/services/crm" },
    { name: "AI & RPA", link: "/services/ai-rpa" },
    { name: "Digital Transformation", link: "/services/digital-transformation" },
    { name: "SAP", link: "/services/sap" },
    // { name: ".net", link: "/services/dotnet" },
    { name: "Customer Solutions", link: "/services/customer-solution" },
  ];
  return (
    <Navbar
      expand={expand}
      className={`fixed-top bg-[#f0f0f3] dark:bg-[#1b202c] text-[black] dark:text-white transition-colors  duration-500 ${localStorage.getItem("theme") === "dark" ? "dark" : ""}`}
      sticky='top'
      style={{ zIndex: "100000" }}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to='/'>
          <img src={isDarkMode ? MacquiresDark : MacquiresLogo} alt='MacquiresLogo' style={{ height: "50px", width: "150px" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement={document.documentElement.dir === "rtl" ? "start" : "end"}>
          <Offcanvas.Header closeButton className='bg-gray-200 dark:bg-[#1b202c] text-[black] dark:text-white transition-colors '>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <img src={isDarkMode ? MacquiresDark : MacquiresLogo} alt='MacquiresLogo' style={{ height: "50px", width: "150px" }} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className='bg-[#f0f0f3] dark:bg-[#1b202c] text-[black]  dark:text-white transition-colors duration-500'>
            <Nav className='justify-content-end align-items-start flex-grow-1 gap-3'>
              <Nav.Link as={Link} to='/' className={location.pathname === "/" ? " active" : " text-[black] dark:text-white duration-500"}>
                {t("header.home")}
              </Nav.Link>

              <Dropdown className={`nav-item active dark:text-white dropdown-hover position-static`} onClick={handleDropdownClickServices}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  className={serviceActive ? "active dark:text-white d-flex align-items-center duration-500" : "dark:text-white d-flex align-items-center duration-500"}
                  id='megaMenuDropdown'
                >
                  {t("header.services")}
                  {serviceActive ? <IoIosArrowUp className='ml-2' /> : <IoIosArrowDown className='ml-2' />}
                </Dropdown.Toggle>
                <Dropdown.Menu className='w-100 mt-0 bg-[#f0f0f3] dark:bg-[#1b202c] dark:text-white transition-colors duration-500' aria-labelledby='megaMenuDropdown'>
                  <Container>
                    <div className='Product-Items'>
                      {ServicesNames.map((service, index) => (
                        <React.Fragment key={index}>
                          <div className='list-group  list-group-flush'>
                            <Link to={service.link} className='dark:text-white text-[black]  ServicesLink'>
                              <label>{service.name}</label>
                            </Link>
                          </div>
                          {index < ServicesNames.length - 1 && <div className='prodcut-vertical-line'></div>}
                        </React.Fragment>
                      ))}
                    </div>
                  </Container>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className={`nav-item dark:text-white active dropdown-hover position-static`} onClick={handleDropdownClick}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  className={active ? "active dark:text-white d-flex align-items-center duration-500" : "dark:text-white d-flex align-items-center duration-500"}
                  id='megaMenuDropdown'
                >
                  {t("header.Product")}
                  {active ? <IoIosArrowUp className='ml-2' /> : <IoIosArrowDown className='ml-2' />}
                </Dropdown.Toggle>
                <Dropdown.Menu className='w-100 mt-0 bg-[#f0f0f3] dark:bg-[#1b202c] dark:text-white transition-colors duration-500' aria-labelledby='megaMenuDropdown'>
                  <Container>
                    <div className='Product-Items'>
                      <div className='col-md-5 mb-3 mb-lg-0'>
                        <div className='list-group list-group-flush'>
                          <div className='dropdown-Items'>
                            <img src={iamsignerLogo} alt='iamsigner logo' className='bg-[#7a78784f] rounded-md' />
                            <div>
                              <h3>{t("header.IamSigner")}</h3>
                              <p>{t("header.IamSignerDesc")}</p>
                              <Link to={"https://iamsigner.com/"} target='_blank' className='ProductLinks'>
                                <label className='mb-0'>{t("header.gotoIamsigner")}</label>
                                <FaArrowRightLong className='mt-1 FaArrowRightLong' />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='prodcut-vertical-line'></div>

                      <div className='col-md-5 mb-3 mb-lg-0'>
                        <div className='list-group list-group-flush'>
                          <div className='dropdown-Items'>
                            <img src={smsLogo} alt='sms logo' className='bg-[#7a78784f] rounded-md' />
                            <div>
                              <h3>{t("header.SMS")}</h3>
                              <p>{t("header.SMSDesc")}</p>
                              <Link to={"https://iamsigner.com/"} target='_blank' className='ProductLinks'>
                                <label className='mb-0'>{t("header.gotoSMS")}</label>
                                <FaArrowRightLong className='mt-1 FaArrowRightLong' />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Link as={Link} to='/about-us' className={location.pathname === "/about-us" ? " active" : " text-[black] dark:text-white duration-500"}>
                {t("header.aboutUs")}
              </Nav.Link>
              <Nav.Link as={Link} to='/blogs' className={location.pathname === "/blogs" ? " active" : " text-[black] dark:text-white duration-500"}>
                {t("header.Blog")}
              </Nav.Link>
              <Nav.Link as={Link} to='/contact-us' className={location.pathname === "/contact-us" ? " active" : " text-[black] dark:text-white duration-500"}>
                {t("header.contactUs")}
              </Nav.Link>
              <LanguageSelector />
              <ThemeToggle />
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;
