import React from "react";
import sapImage from "../../../../Assets/images/SAP services.svg";
import "./sap.css";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import { useTranslation } from "react-i18next";
import MacquriresSap from "../../../../Assets/images/MacquiresSAP.svg";
import MacquriresWorld from "../../../../Assets/images/MacquiresWorld.svg";
import MacquriresPlatform from "../../../../Assets/images/MacquiresPlatform.svg";
import MacquriresTech from "../../../../Assets/images/MacquiresTech.svg";

function SAP() {
  const { t } = useTranslation();
  return (
    <div>
      <Header />
      <section>
        <img src={sapImage} alt='Software as Product' className='SAPImage' />
      </section>

      <section className='secondSectionSAP bg-gray-200  dark:bg-[#1b202c] dark:text-white transition-colors duration-500'>
        <h1>{t("SAP.SAPBusiness")}</h1>
        <p>{t("SAP.SAPDESC")}</p>
      </section>

      <section className='SAPThird bg-[#fbfbfb] dark:bg-[#000410] dark:text-white'>
        <div>
          <h1>{t("SAP.MaximizeYourSAP")}</h1>
          <p>{t("SAP.LetourSpecialists")}</p>
        </div>

        <div className='SAPMAINSEC'>
          <div className=' bg-[#fff]  dark:bg-[#1b202c] text-[black] dark:text-white  SAPSection'>
            <img src={MacquriresSap} alt='Macquires & SAP Services' />
            <div>
              <ul>
                <label className='fw-bold'>{t("SAP.MacquiresServices")}</label>
                <li>{t("SAP.ApplicationImplementation")}</li>
                <li>{t("SAP.ApplicationUpgrade")}</li>
                <li>{t("SAP.ApplicationIntegration")}</li>
                <li>{t("SAP.ApplicationCustomization")}</li>
                <li>{t("SAP.ManagingApplication")}</li>
                <li>{t("SAP.ManagingTechnologyPlatform")}</li>
              </ul>
            </div>
          </div>
          <div className=' bg-[#fff] dark:bg-[#1b202c] SAPSection'>
            <img src={MacquriresWorld} alt='Macquires & SAP Services' />
            <div>
              <ul>
                <label className='fw-bold'>{t("SAP.MacquiresSolutionsArea")}</label>
                <li>{t("SAP.ERPFinanceManagement")}</li>
                <li>{t("SAP.SupplyChainManagement")}</li>
                <li>{t("SAP.CustomerExperienceManagement")}</li>
                <li>{t("SAP.HRPeopleEngagementManagement")}</li>
                <li>{t("SAP.BusinessPlatformManagement")}</li>
              </ul>
            </div>
          </div>
          <div className=' bg-[#fff] dark:bg-[#1b202c] SAPSection'>
            <img src={MacquriresPlatform} alt='Macquires & SAP Services' />
            <div>
              <ul>
                <label className='fw-bold'>{t("SAP.SAPS/MacquiresPlatformSolutions")}</label>
                <li>{t("SAP.SAPS/4HANA")} </li>
                <li>{t("SAP.SAPHANA")} </li>
                <li>{t("SAP.SAPC/4HANA")} </li>
                <li>{t("SAP.SAPB1")} </li>
              </ul>
            </div>
          </div>
          <div className=' bg-[#fff] dark:bg-[#1b202c] SAPSection'>
            <img src={MacquriresTech} alt='Macquires & SAP Services' />
            <div>
              <ul>
                <label className='fw-bold'>{t("SAP.MacquiresServices")}</label>
                <li>{t("SAP.CostEfficiency")}</li>
                <li>{t("SAP.GrowingBusiness")}</li>
                <li>{t("SAP.ResourceOptimization")}</li>
                <li>{t("SAP.HelpDecisionMaker")}</li>
                <li>{t("SAP.Reliability")}</li>
                <li>{t("SAP.AgilityInnovation")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default SAP;
