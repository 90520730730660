import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./style.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import planetImage from "../../../Assets/images/planet.svg";
import PlanetMobile from "../../../Assets/images/planetMobile.svg";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Carousel from "./Carosuel";

function ContactUs() {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  return (
    <div>
      <Header />
      <Carousel />
      <div className='dark:bg-[#000410] dark:text-white min-h-screen flex items-center'>
        <div className='w-100 p-4'>
          <div className={`flex flex-wrap align-items-center md:-mx-4 ${isMobile ? "flex-col-reverse" : ""}`}>
            <div className={`w-full lg:w-1/2 md:px-10 `}>
              <h2 className='text-3xl mb-8'>{t("contactUs.GetInTouch")}</h2>
              <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 mb-3'>
                <Row>
                  <Col md={5}>
                    <div>
                      <label className='fw-bold' htmlFor='name'>
                        {t("contactUs.Name")}
                      </label>
                      <input type='text' id='name' placeholder={t("contactUs.FullName")} {...register("name", { required: true })} className='dark:bg-[#1b202c] bg-[#f0f0f3] block w-full p-2' />
                      {errors.name && <span className='text-red-500'>{t("contactUs.NameRequired")}</span>}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div>
                      <label className='fw-bold' htmlFor='email'>
                        {t("contactUs.Email")}
                      </label>
                      <input type='email' placeholder='email@example.com' id='email' {...register("email", { required: true })} className='dark:bg-[#1b202c] bg-[#f0f0f3] block w-full p-2' />
                      {errors.email && <span className='text-red-500'>{t("contactUs.EmailRequired")}</span>}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div>
                      <label className='fw-bold' htmlFor='company'>
                        {t("contactUs.Company")}
                      </label>
                      <input type='text' id='company' placeholder={t("contactUs.companyName")} {...register("company")} className='dark:bg-[#1b202c] bg-[#f0f0f3] block w-full p-2' />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div>
                      <label className='fw-bold' htmlFor='subject'>
                        {t("contactUs.Subject")}
                      </label>
                      <input type='text' id='subject' placeholder={t("contactUs.HowCanIHelpyou")} {...register("subject")} className='dark:bg-[#1b202c] bg-[#f0f0f3] block w-full p-2' />
                    </div>
                  </Col>
                  <Col md={10}>
                    <div>
                      <label className='fw-bold' htmlFor='message'>
                        {t("contactUs.Message")}
                      </label>
                      <textarea
                        id='message'
                        placeholder={t("contactUs.YourMessage")}
                        cols={4}
                        rows={4}
                        {...register("message", { required: true })}
                        className='dark:bg-[#1b202c] bg-[#f0f0f3] block w-full p-2'
                      ></textarea>
                      {errors.message && <span className='text-red-500'>{t("contactUs.MessageRequired")}</span>}
                    </div>
                  </Col>
                </Row>
                <button type='submit' className='pulse'>
                  {t("contactUs.SendMessage")}
                </button>
              </form>
            </div>
            <div className={`w-full lg:w-1/2 flex items-center justify-end mb-3 `}>
              <img src={isMobile ? PlanetMobile : planetImage} alt='planet loading' className='max-w-full h-auto planetImage' />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
