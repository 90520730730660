import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import blogImage from "../../../Assets/images/Blog.svg";
import blogDark from "../../../Assets/images/Blog Dark mode.svg";
import "./style.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import webImage from "../../../Assets/images/webImage.svg";
import techImage from "../../../Assets/images/TechImage.svg";
import jumpImage from "../../../Assets/images/jumpImage.svg";
import OmarImage from "../../../Assets/images/UmerIamge.svg";
import { useTranslation } from "react-i18next";

function Blog() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState("LatestPost");
  const navigate = useNavigate();

  const handleLastestPost = () => {
    setViewMode("LatestPost");
  };

  const handleLinkedinPost = () => {
    setViewMode("LinkedinPost");
  };

  const navigateToDetail = (index) => {
    navigate(`/blog/${index}`);
  };

  useEffect(() => {
    const checkDarkMode = () => {
      const darkModeEnabled = document.documentElement.classList.contains("dark");
      setIsDarkMode(darkModeEnabled);
    };

    checkDarkMode();

    const observer = new MutationObserver(() => {
      checkDarkMode();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const posts = [
    {
      image: webImage,
      title: t("home.MacquiresHelp"),
      description: t("home.AbilityAnalyze"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
    },
    {
      image: jumpImage,
      title: t("home.MacquiresHelpCustomer"),
      description: t("home.MacquiresHelpCustomerDes"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
    },
    {
      image: techImage,

      title: t("home.MacquiresBusinessApplication"),
      description: t("home.SAPBusiness"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
    },
  ];

  return (
    <div >
      <Header />
      <div >
        <img src={isDarkMode ? blogDark : blogImage} alt='Blog' className='full-screen-image' />
      </div>
      <div className='md:p-[5rem] p-[20px] flex flex-col gap-4  bg-[#fff] dark:bg-[#000410] text-[black] dark:text-white transition-colors duration-500 '>
        <h1 className='fw-bold'>{t("Blog.MacquiresBlog")}</h1>

        <div className='d-flex gap-3 '>
          <button className={`blogButton ${viewMode === "LatestPost" ? "blogactive" : ""}`} onClick={handleLastestPost}>
            {t("Blog.LatestPost")}
          </button>
          <button className={`blogButton ${viewMode === "LinkedinPost" ? "blogactive" : ""}`} onClick={handleLinkedinPost}>
            {t("Blog.LinkedinPosts")}
          </button>
        </div>

        {viewMode === "LatestPost" && (
          <div className='blogContainer'>
            {posts.map((post, index) => (
              <div key={index} className='post-card-blog ' onClick={() => navigateToDetail(index)}>
                <img src={post.image} alt={`Post ${index + 1}`} className='post-image' />
                <div className='blog-content   bg-[#e5e7eb] dark:bg-[#1b202c] text-[black] dark:text-white'>
                  <h3 className='post-title'>{post.title}</h3>
                  <p className='post-description'>{post.description}</p>
                  <hr className='bg-[#e5e7eb] dark:bg-[#1b202c] dark:text-white w-full rounded-b-3xl' />
                  <div className='post-footer'>
                    <img src={OmarImage} alt='Author' className='author-image-blog' />
                    <div className='author-info-blog'>
                      <p className='author-name mb-0'>{post.author}</p>
                      <p className='post-date mb-0'>{post.date}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {viewMode === "LinkedinPost" && (
          <div className='blogContainer'>
            {posts.map((post, index) => (
              <div key={index} className='post-card-blog ' onClick={() => navigateToDetail(index)}>
                <img src={post.image} alt={`Post ${index + 1}`} className='post-image' />
                <div className='blog-content   bg-[#e5e7eb] dark:bg-[#1b202c] text-[black] dark:text-white'>
                  <h3 className='post-title'>{post.title}</h3>
                  <p className='post-description'>{post.description}</p>
                  <hr className='bg-[#e5e7eb] dark:bg-[#1b202c] dark:text-white w-full rounded-b-3xl' />
                  <div className='post-footer'>
                    <img src={OmarImage} alt='Author' className='author-image' />
                    <div className='author-info'>
                      <p className='author-name mb-0'>{post.author}</p>
                      <p className='post-date mb-0'>{post.date}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
