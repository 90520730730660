import React from "react";
import Slider from "react-slick";
import "./OracleServices.css";
import { useTranslation } from "react-i18next";


function OrcaleSlider() {
    const { t } = useTranslation();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className='p-[20px] dark:bg-[#000410] dark:text-white transition-colors duration-500'>
      <div className='slide-1'>
        <div className='UlSection'>
          <ul className='mb-0'>
            <label className='fw-bold'>{t("Servives.MacquiresOrcale")}</label>
            <li>{t("Servives.OracleFinancialManagement")}</li>
            <li>{t("Servives.OracleSupplyChainManagement")}</li>
            <li>{t("Servives.OracleHumanCapitalManagement")}</li>
            <li>{t("Servives.OracleEnterprisePerformanceManagement")}</li>
            <li>{t("Servives.OracleProjectPortfolioManagement")}</li>
            <li>{t("Servives.OracleCustomerRelationship")}</li>
            <li>{t("Servives.OracleBusinessIntelligenceApplications")}</li>
          </ul>
        </div>
      </div>
      <div className='slide-2'>
        <div className='UlSection'>
          <ul className='mb-0'>
            <label className='fw-bold'>{t("Servives.MacquiresPlatformSolutions")}</label>
            <li>{t("Servives.OracleCloudTechnologySolution")}</li>
            <li>{t("Servives.OracleTechnologySolution")}</li>
            <li>{t("Servives.OracleApplicationExpress")}</li>
            <li>{t("Servives.OracleBusinessIntelligence")}</li>
            <li>{t("Servives.OracleProjectPortfolioManagement")}</li>
            <li>{t("Servives.OracleMasterDataManagement")}</li>
            <li>{t("Servives.OracleHyperion")}</li>
          </ul>
        </div>
      </div>
      <div className='slide-3'>
        <div className='UlSection'>
          <ul className='mb-0'>
            <label className='fw-bold'>{t("Servives.MacquiresOracleTechnologies")}</label>
            <li>{t("Servives.CostEfficiency")}</li>
            <li>{t("Servives.ResourceOptimization")}</li>
            <li>{t("Servives.Reliability")}</li>
            <li>{t("Servives.AgilityInnovation")}</li>
          </ul>
        </div>
      </div>
      <div className='slide-4'>
        <div className='VerticalContainer'>
          <ul className='mb-0'>
            <label className='fw-bold'>{t("Servives.MacquiresOracleServices")}</label>
            <li>{t("Servives.OracleApplicationModernization")}</li>
            <li>{t("Servives.OracleSystemAssessment")}</li>
            <li>{t("Servives.OracleInfrastructureManagingServices")}</li>
            <li>{t("Servives.OracleUpgradesService")}</li>
            <li>{t("Servives.OracleMigrationServices")}</li>
            <li>{t("Servives.OracleImplementation")}</li>
            <li>{t("Servives.OracleSystemImplementation")}</li>
            <li>{t("Servives.TestingService")}</li>
            <li>{t("Servives.OracleIntegration")}</li>
            <li>{t("Servives.DevelopmentOracleProjects")}</li>
            <li>{t("Servives.ProjectManagementService")}</li>
            <li>{t("Servives.OracleService")}</li>
            <li>{t("Servives.OracleSupportService")}</li>
            <li>{t("Servives.OraclePlatform")}</li>
            <li>{t("Servives.OracleSoftware")}</li>
          </ul>
        </div>
      </div>
    </Slider>
  );
}

export default OrcaleSlider;
