import React, { useState } from "react";
import { FiPhone } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
import { CiLocationOn } from "react-icons/ci";
import "./style.css";
import SaudiArabia from "../../../Assets/images/Saudi Arabia.svg";
import Islamabad from "../../../Assets/images/Islamabad.svg";
import Dubai from "../../../Assets/images/UAE.svg";
import Jordan from "../../../Assets/images/Jordan.svg";
import { useTranslation } from "react-i18next";

const ImageGallery = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { t } = useTranslation();

  const images = [
    {
      src: SaudiArabia,
      alt: "Saudi Arabia",
      name: t("contactUs.SaudiArabia"),
      details: [
        { icon: <FiPhone />, text: "+966 500067 158" },
        { icon: <FiPhone />, text: "+966 500172 382" },
        { icon: <TfiEmail />, text: "info@macquires.com" },
        { icon: <CiLocationOn size={30} />, text: t("contactUs.SaudiLocation") },
      ],
    },
    {
      src: Jordan,
      alt: "Jordan",
      name: t("contactUs.Jordan"),
      details: [
        { icon: <FiPhone />, text: "+962 795946 277" },
        { icon: <TfiEmail />, text: "info@macquires.com" },
        { icon: <CiLocationOn />, text: t("contactUs.jordanLocation") },
      ],
    },
    {
      src: Islamabad,
      alt: "Islamabad",
      name: t("contactUs.Pakistan"),
      details: [
        { icon: <FiPhone />, text: "+923 439650 517" },
        { icon: <TfiEmail />, text: "info@macquires.com" },
        { icon: <CiLocationOn />, text: t("contactUs.PakistanLocation") },
      ],
    },
    {
      src: Dubai,
      alt: "Dubai",
      name: t("contactUs.Dubai"),
      details: [
        { icon: <FiPhone />, text: "+971 567666 234" },
        { icon: <FiPhone />, text: "+971 581263 127" },
        { icon: <TfiEmail />, text: "info@macquires.com" },
        { icon: <CiLocationOn />, text: t("contactUs.DubaiLocation") },
      ],
    },
  ];

  return (
    <div className='CarosuelContainer'>
      {images.map((image, index) => (
        <div
          key={index}
          className={`image-wrapper ${index === 0 ? "first" : ""} ${hoveredIndex === index ? "hovered" : ""}`}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <img src={image.src} alt={image.alt} className='gallery-image' />
          <div className='image-name'>{image.name}</div>
          <div className={`image-overlay ${hoveredIndex === index || (hoveredIndex === null && index === 0) ? "visible" : ""}`}>
            <div className='details'>
              {image.details.map((detail, i) => (
                <p key={i} className='detail-item mb-2'>
                  {detail.icon} <span>{detail.text}</span>
                </p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
