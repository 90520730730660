import React, { useRef } from "react";
import Slider from "react-slick";
import "./Recentpost.css";
import webImage from "../../../Assets/images/webImage.svg";
import techImage from "../../../Assets/images/TechImage.svg";
import jumpImage from "../../../Assets/images/jumpImage.svg";
import OmarImage from "../../../Assets/images/UmerIamge.svg";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { useTranslation } from "react-i18next";

function RecentPost() {
  const sliderRef = useRef(null);
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const posts = [
    {
      image: webImage,
      title: t("home.MacquiresHelp"),
      description: t("home.AbilityAnalyze"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
    },
    {
      image: techImage,
      title: t("home.MacquiresHelpCustomer"),
      description: t("home.MacquiresHelpCustomerDes"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
    },
    {
      image: jumpImage,
      title: t("home.MacquiresBusinessApplication"),
      description: t("home.SAPBusiness"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
    },
    {
      image: webImage,
      title: t("home.MacquiresHelp"),
      description: t("home.AbilityAnalyze"),
      author: t("home.OmarAlFarahid"),
      date: t("home.DECEMBER"),
    },
  ];

  return (
    <div className='recent-post-section p-5 bg-[#fff] dark:bg-[#000410] text-[black] dark:text-white transition-colors duration-500 '>
      <div className='section-header'>
        <h2 className='section-title my-[3rem] '>{t("home.RecentPost")}</h2>
        <div className='nav-buttons'>
          <button onClick={() => sliderRef.current.slickPrev()} className='prev-button'>
            <MdArrowBackIos />
          </button>
          <button onClick={() => sliderRef.current.slickNext()} className='next-button'>
            <MdArrowForwardIos />
          </button>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {posts.map((post, index) => (
          <div key={index} className='post-card p-2'>
            <img src={post.image} alt={`Post ${index + 1}`} className='post-image' />
            <div className='post-content   bg-[#e5e7eb] dark:bg-[#1b202c] text-[black] dark:text-white'>
              <h3 className='post-title'>{post.title}</h3>
              <p className='post-description'>{post.description}</p>
              <hr className='bg-[#e5e7eb] dark:bg-[#1b202c] dark:text-white w-full rounded-b-3xl' />
              <div className='post-footer'>
                <img src={OmarImage} alt='Author' className='author-image' />
                <div className='author-info'>
                  <p className='author-name mb-0'>{post.author}</p>
                  <p className='post-date mb-0'>{post.date}</p>
                </div> 
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default RecentPost;
