import React from "react";
import AIRPA from "../../../../Assets/images/AI and RPA services.svg";
import "./AI.css";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import { useTranslation } from "react-i18next";

function AIandRPA() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />

      <section className='AiRpa-section'>
        <div className='AiRpa-container'>
          <img src={AIRPA} alt='AI & RPA' className='AIRPAimage' />
          <div className='AiRpa-overlay'>
            <h1 className='AiRpa-Subtext'>{t("AIRPA.AIRPA")}</h1>
          </div>
        </div>
      </section>

      <section className='AiSection dark:bg-[#000410] dark:text-white transition-colors duration-500'>
        <h1>{t("AIRPA.RoboticProcess")}</h1>
        <p>{t("AIRPA.Processautomation")}</p>
        <p>{t("AIRPA.MacquiresRPA")}</p>
        <p>{t("AIRPA.Incontrastto")}</p>
      </section>
      <Footer />
    </div>
  );
}

export default AIandRPA;
